export const environment = {
  production: true,
  environment: 'preprod',
  baseApiUrl: 'https://api.preprod.fair-dogs.com/api/v1',
  siteKeyForCaptcha: '6LeKksYbAAAAADzl4wrNcrn3t9CE0WVL0g88Xoh_',
  frontEndDomain: 'https://app.preprod.fair-dogs.com',
  serverDomain: 'https://api.preprod.fair-dogs.com/',
  wpApiUrl: "https://preprod.fair-dogs.com/",
  googleMaps: {
    apiKey: 'AIzaSyDOdKEfMn_mzAlCFLSBGz2vVwkcy2cPULQ'
  },
  allowSmartlook: false,
  blogsApi: 'https://preprod.fair-dogs.com/blog/wp-json/wp/v2/blogs?orderby=rand',
  SENTRY_DSN: "https://092d3e1c616fd5e4f3c34f8a0f0f354c@o4506978868527104.ingest.us.sentry.io/4506979220717568",
};